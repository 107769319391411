import React from "react";
import { Helmet } from "react-helmet";
import { Location } from "@reach/router";

const theme = {
  title: "Wisconsin Foundation Repairs",
  urlWithProtocol: "https://wisconsinfoundationrepairs.com",
};

export default function SeoHelper({
  description = "",
  title,
  image = "",
  isPost = false,
}) {
  const metaTitle = `${title} | ${theme.title}`;

  return (
    <Location>
      {({ location }) => {
        const { pathname, hostname } = location;
        if (hostname === "localhost" && pathname[pathname.length - 1] !== "/") {
          throw new Error(
            `Hi there! 👋  --- Our site uses urls with trailing slashes. And the current path you are on, ${pathname}, does not end with a trailing slash.
            Please make sure any links to pages end with a trailing slash.
            `
          );
        }

        return (
          <Helmet
            defer={false}
            htmlAttributes={{ lang: "en", class: "font-base" }}
            title={metaTitle}
            meta={[
              { property: `og:title`, content: metaTitle },
              { name: `description`, content: description },
              { property: `og:description`, content: description },
              { property: `og:image`, content: image },
              { property: `og:type`, content: isPost ? "article" : "website" },
              { property: "og:locale", content: "en_US" },
              { property: "og:url", content: location.href },
              { property: "og:site_name", content: theme.title },
              { itemprop: "name", content: theme.title },
              { itemprop: "url", content: theme.urlWithProtocol },
            ]}
          >
            <link rel="canonical" href={location.href} />
            <body itemScope itemType="http://schema.org/WebPage"></body>
          </Helmet>
        );
      }}
    </Location>
  );
}
